import { DashboardUrls } from "../Contexts/DashboardContext/Types";

export const ETH_DASHBOARDS: DashboardUrls = {
  landingPage: "https://data.flashbots.net/public/dashboard/3cd462d0-5423-466f-bc8e-75b21083f735",
  leaderboards :[
    {
      name: "Latest MEV transactions",
      link: "https://data.flashbots.net/public/dashboard/60e8da91-744a-4704-9331-499ac2e6d430#titled=false",
    },
    {
      name: "Top MEV transactions (all time)",
      link: "https://data.flashbots.net/public/dashboard/cef7a420-315a-451f-b86a-4292ff5c23f9#titled=false",
    },
  ]
}
